.App {
  text-align: center;
  background-image: url('./components/layered-waves-haikei.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;

  overflow: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #dc3d4b;
  clip-path: ellipse(300% 100% at 237.5% 0%);
}

.customCards {
  border: 1px solid #fff;
}

.customCards:hover {
  transform: scale(1.01);
  transition: all 0.21s ease-in-out;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  border-bottom: #61dafb 5px solid;

}
